<template>
    <!-- Navigation-->
    <nav class="navbar navbar-expand-lg navbar-light fixed-top" id="mainNav">
        <div class="container px-4 px-lg-5" style="justify-content:right;">
            <a class="navbar-brand" href="#app"><img class="img-navbar" src="assets/AA_icon.png" alt="..." /></a>
            <button class="navbar-toggler navbar-toggler-right" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
                Menu
                <i class="fas fa-bars"></i>
            </button>
            <div class="collapse navbar-collapse" id="navbarResponsive" style="text-align:right;">
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item"><a class="nav-link" href="#why">Why</a></li>
                    <li class="nav-item"><a class="nav-link" href="#how">How</a></li>
                    <li class="nav-item"><a class="nav-link" href="#what">What</a></li>
                    <li class="nav-item"><a class="nav-link" href="#team">Team</a></li>
                    <li class="nav-item"><a class="nav-link" href="#contact">Contact</a></li>
                </ul>
            </div>
        </div>
    </nav>
    <!-- Masthead-->
    <header class="masthead">
        <div class="container px-4 px-lg-5 d-flex h-100 align-items-center justify-content-center">
            <div class="d-flex justify-content-center">
                <div class="text-center">
                    <h1 class="mx-auto my-0">AravisAdvisors</h1>
                    <h2 class="text-white mx-auto mt-2 mb-5">Technology to scale up Continuous Cover Forestry</h2>
                </div>
            </div>
        </div>
    </header>
    <!-- WHY test-->
    <section class="about-section text-center" id="why">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8">
                    <h1 class="text-black mb-4">Why</h1>
                    <h3 class="text-black mx-auto mt-10 mb-10">
                        We are convinced that nature itself holds the keys to combat climate change.
                    </h3>
                    <h3 class="text-black mx-auto mt-10 mb-10">
                        Close-to-nature forest ecosystems sequestrate carbon for the long term, 
                        produce wood as a renewable raw material and harbour biodiversity that 
                        increases forest resilience in the face of climate change.
                    </h3>
                </div>
            </div>
            <!--img class="img-fluid" src="assets/img/ipad.png" alt="..." /-->
        </div>
    </section>
    <!-- HOW -->
    <section class="how-section bg-light-blue text-center" id="how">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8">
                    <h1 class="text-black mb-4">How</h1>
                </div>
            </div>
            <!-- Land identification-->
            <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                <div class="col-xl-4 col-lg-4"><img class="img-fluid mb-3 mb-lg-0" src="../assets/img/CCF.jpg" alt="..." /></div>
                <div class="col-xl-8 col-lg-8">
                    <div class="featured-text text-center">
                        <h2 class="text-black mx-auto mt-5 mb-5">Continuous Cover Forestry</h2>

                        <h5 class="text-black text-lg-start mb-5 mt-5">
                            Continuous Cover Forestry is a forest management methodology that avoids 
                            clear-cutting, thereby crucially preserving carbon sequestration and 
                            forest ecosystem services at all times.    
                        </h5>

                        <h5 class="text-black text-lg-start mb-5 mt-5">
                            Continuous Cover Forestry shuns clear-cutting and thus keeps the CO2 
                            in the ground.  It also aims to produce a higher proportion of large 
                            diameter quality timber, used for goods that keep CO2 sequestered after 
                            the tree is harvested.    
                        </h5>
                        <h5 class="text-black text-lg-start mb-5 mt-5">
                            Continuous Cover Forestry has numerous positive side effects:    
                        </h5>
                        <h5 class="text-black text-lg-start mb-5 mt-5">
                        <ul>
                            <li class="text-black text-lg-start mb-2 mt-2">soil preservation</li>
                            <li class="text-black text-lg-start mb-2 mt-2">improved water retention</li>
                            <li class="text-black text-lg-start mb-2 mt-2">biodiversity increase</li>
                            <li class="text-black text-lg-start mb-2 mt-2">improved air filtration</li>
                            <li class="text-black text-lg-start mb-2 mt-2">local climate improvement through evapotranspiration</li>
                            <li class="text-black text-lg-start mb-2 mt-2">preserved landscapes</li>
                        </ul>
                        </h5>

                        <h5 class="text-black text-lg-start mb-5 mt-5">
                            From an economic perspective Continuous Cover Forestry maintains the forests' productive 
                            function and contributes to securing the wood supply chain in Europe.
                        </h5>

                        <h5 class="text-black text-lg-start mb-5 mt-5">
                            Our mission is to increase permanent forest cover in Europe.    
                        </h5>
                    </div>
                </div>
            </div>
            <div class="row gx-4 gx-lg-5 justify-content-center">
            </div>
        </div>
    </section>
    <!-- WHAT-->
    <section class="what-section bg-white text-start" id="what">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8">
                    <h1 class="text-black mb-4 text-center">What</h1>
                </div>
            </div>
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8">
                    <h3 class="text-black text-center mx-auto mt-10 mb-10">AravisAdvisors pioneers technology for Continuous Cover Forestry.</h3>
                </div>
            </div>
            <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                <div class="col-xl-4 col-lg-4"><img class="img-fluid mb-3 mb-lg-0" src="../assets/img/forest_3D_model.png" alt="..." /></div>
                <div class="col-xl-8 col-lg-8">
                    <div class="featured-text text-center text-lg-left">
                        <h2 class="text-black mx-auto mt-5 mb-5">
                            the Forest Modeller
                        </h2>
                        <h4 class="text-black mb-5 mt-5">
                            for the initial assessment and subsequent management of Continuous Cover Forestry projects.
                        </h4>
                    </div>
                </div>
            </div>

            <div class="row gx-0 mb-4 mb-lg-5 align-items-center">
                <div class="col-xl-8 col-lg-8">
                    <div class="featured-text text-center text-lg-left">
                        <h2 class="text-black mx-auto mt-5 mb-5 me-5">
                            the Discoverer
                        </h2>
                        <h4 class="text-black mb-5 mt-5">
                            for the identification of suitable land for Continuous Cover Forestry projects via satellite imagery.
                        </h4>
                    </div>
                </div>
                <div class="col-xl-4 col-lg-4"><img class="img-fluid mb-3 mb-lg-0" src="../assets/img/land_detection.png" alt="..." /></div>
            </div>
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8">
                    <h3 class="text-black text-center mx-auto mt-10 mb-10">The software allows for a technology enabled investment approach to 
                        nature-based solutions that is profitable and benefits our climate and environment.
                    </h3>
                </div>
            </div>
        </div>
    </section>
    <!-- TEAM-->
    <section class="projects-section bg-light-blue text-center" id="team">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8">
                    <h1 class="text-black mb-4">Team</h1>
                </div>
            </div>
    
            <!-- MMA -->

            <div class="row gx-0 mb-5 mb-lg-0 justify-content-center">
                <div class="col-lg-3"><img class="img-fluid" src="../assets/img/MMA.png" alt="..." /></div>
                <div class="col-lg-9">
                    <div class="bg-body text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-center text-lg-left">
                                <h4 class="text-black">Michael Martin - Founder</h4>
                                <br>
                                <h5 class="mb-0 text-black-50">
                                    Michael is the strategy and finance lead.  
                                    Michael is a private forest owner and has a 
                                    professional background in accounting and finance.
                                </h5>
                                <div class="social d-flex justify-content-center">
                                    <a class="mx-2" href="https://www.linkedin.com/in/michael-jj-martin-5a67037/" target="_blank"><i class="fab fa-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- SCO-->
            <div class="row gx-0 justify-content-center">
                <div class="col-lg-3"><img class="img-fluid" src="../assets/img/SCO.png" alt="..." /></div>
                <div class="col-lg-9 order-lg-first">
                    <div class="bg-body text-center h-100 project">
                        <div class="d-flex h-100">
                            <div class="project-text w-100 my-auto text-center text-lg-right">
                                <h4 class="text-black">Samuel Courtois - Co-Founder</h4>
                                <br>
                                <h5 class="mb-0 text-black-50">Samuel is the technology lead.  
                                    Samuel is an engineer with a professional background 
                                    in circular economy consulting.
                                </h5>
                                <div class="social d-flex justify-content-center">
                                    <a class="mx-2" href="https://www.linkedin.com/in/samuel-courtois-209200118/" target="_blank"><i class="fab fa-linkedin"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- Contact-->
    <section class="contact-section bg-blue text-center" id="contact">
        <div class="container px-4 px-lg-5">
            <div class="row gx-4 gx-lg-5 justify-content-center">
                <div class="col-lg-8">
                    <h1 class="text-black mb-4">Contact</h1>
                </div>
            </div>
            <div class="row gx-4 gx-lg-5">
                <div class="col-md-6 mb-3 mb-md-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <i class="fas fa-map-marked-alt text-primary mb-2"></i>
                            <h4 class="text-uppercase m-0">Address</h4>
                            <hr class="my-4 mx-auto" />
                            <div class="small text-black-50">Luxembourg, Luxembourg</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 mb-3 mb-md-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <i class="fas fa-envelope text-primary mb-2"></i>
                            <h4 class="text-uppercase m-0">Email</h4>
                            <hr class="my-4 mx-auto" />
                            <div class="small text-black-50"><a href="mailto:info@aravisadvisors.com">info@aravisadvisors.com</a></div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-4 mb-3 mb-md-0">
                    <div class="card py-4 h-100">
                        <div class="card-body text-center">
                            <i class="fas fa-mobile-alt text-primary mb-2"></i>
                            <h4 class="text-uppercase m-0">Phone</h4>
                            <hr class="my-4 mx-auto" />
                            <div class="small text-black-50">+1 (555) 902-8832</div>
                        </div>
                    </div>
                </div> -->
            </div>
            <!-- <div class="social d-flex justify-content-center">
                <a class="mx-2" href="#!"><i class="fab fa-twitter"></i></a>
                <a class="mx-2" href="#!"><i class="fab fa-facebook-f"></i></a>
                <a class="mx-2" href="#!"><i class="fab fa-github"></i></a>
            </div> -->
        </div>
    </section>
    <!-- Footer-->
    
    <footer class="footer bg-blue small text-center text-black-50"><div class="container px-4 px-lg-5">Copyright &copy; AravisAdvisors SARL-S 2024</div></footer>
</template>

<script>


export default {
  name: 'OnePager',
  props: {
    msg: String
  },
  mounted() { 
      //let BootstrapBundleMinJS = document.createElement('script')
      //BootstrapBundleMinJS.setAttribute('src', 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js')
      //document.body.appendChild(BootstrapBundleMinJS)
    //   document.head.appendChild(BootstrapBundleMinJS)    

      let customJSScript = document.createElement('script')
      customJSScript.setAttribute('type', 'module')
      customJSScript.setAttribute('src', 'js/scripts.js')
      document.body.appendChild(customJSScript)
    //   document.head.appendChild(customJSScript)

      let sbFormLatestJS = document.createElement('script')
      sbFormLatestJS.setAttribute('src', 'https://cdn.startbootstrap.com/sb-forms-latest.js')
      document.body.appendChild(sbFormLatestJS)
    //   document.head.appendChild(sbFormLatestJS)
  }
}
</script>
