<template>
  <OnePager />
</template>

<script>
import OnePager from './components/OnePager.vue'


export default {
  name: 'App',
  components: {
    OnePager
  },
  mounted() { 
      //let BootstrapBundleMinJS = document.createElement('script')
      //BootstrapBundleMinJS.setAttribute('src', 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.3/dist/js/bootstrap.bundle.min.js')
      //document.head.appendChild(BootstrapBundleMinJS)
  }
}

</script>

<!-- <style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style> -->
